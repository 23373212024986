/*
Hide and show 

*/

import { ComponentRef } from '@wix/platform-editor-sdk';
import { getElementsData, getCategoryData } from './showHideData';
import {
  FlowEditorSDK,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import { ElementData } from '@wix/editor-platform-sdk-types';

export const openShowHidePanel = (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  flowAPI: PlatformControllerFlowAPI,
): Promise<void> => {
  console.log('widgetRef:', widgetRef);
  const getCollapsedRefComponentByRole = async (role) => {
    console.log('role:', role);
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        'token',
        {
          componentRef: widgetRefHost,
          // @ts-expect-error temp until types are GAed
          includeInnerCollapsed: true,
        },
      );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );
    return collapsedRefComponent[0]?.componentRef;
  };

  const getCompToHide = async (componentRef) => {
    const type = await editorSDK.components.getType('token', { componentRef });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComp = async (componentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
    return editorSDK.application.livePreview.refresh('token', {
      shouldFetchData: false,
      source: 'AFTER_DB_CHANGE',
    });
  };

  const hideComp = async (componentRef) => {
    await editorSDK.components.refComponents.collapseReferredComponent(
      'token',
      {
        componentRef,
      },
    );
  };

  const addCompHandler = async ({ role }, compRef) => {
    const componentRef =
      compRef || (await getCollapsedRefComponentByRole(role));
    return showComp(componentRef);
  };

  const removeCompHandler = async (compRef) => {
    const compToHide = await getCompToHide(compRef);
    return hideComp(compToHide);
  };

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    categoriesData: getCategoryData(flowAPI),
    elementsData: getElementsData(flowAPI),
    addComponentHandler: addCompHandler,
    removeComponentHandler: removeCompHandler,
    subtitle: flowAPI.translations.t('app.layout.subtitle'),
  });
};
