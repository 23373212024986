import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { components } from './components';

const widgetPresetsData = [
  {
    // preset data
    id: 'variants-lhiu55u81',
    src: 'cardAndOverlap_circle',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    id: 'ariants-lj5exgrb',
    src: 'sideBySide_circle',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    id: 'variants-lhrmlzhi',
    src: 'cardAndOverlap_square',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    id: 'variants-lhx7jbpa',
    src: 'sideBySide_square',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
];

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  appData,
  flowAPI,
) => {
  console.log('appData:', appData);
  const t = flowAPI.translations.t;
  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('app.design.title'),
      // presetsTitle: 'Discover More Designs', //No presets at this point
    });

    // widgetDesignBuilder.setPresets(widgetPresetsData as any);

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder.set({
        label: t('app.design.section.title'),
      });

      tabBuilder.groups().set({
        roles: [components.image.toString().replace('#', '')],
      });
    });
  });

  // additional manifest configuration
};
