import { PLATFORM } from '../utils/constants';
import type {
  FlowEditorSDK,
  EditorScriptFlowAPI,
} from '@wix/yoshi-flow-editor';

export const componentDeletedEventListener = async ({
  editorSDK,
  flowAPI,
  widgetPresetsData,
  isStudio,
}: {
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
  widgetPresetsData: any;
  isStudio: boolean;
}) => {
  await editorSDK.addEventListener('componentDeleted', async (event) => {
    const viewPort = await editorSDK.info.getCurrentViewport();
    // console.log('Event Listener:', event);
    const componentRef = event.detail.componentRef;

    const ancestors = await editorSDK.components.getAncestors('', {
      componentRef,
    });
    // console.log('ancestors:', ancestors);

    const allAppRefComponents =
      await editorSDK.document.components.refComponents.getAllAppRefComponents(
        'token',
      );
    // console.log('allAppRefComponents:', allAppRefComponents);

    if (
      ancestors.length == 0 &&
      allAppRefComponents.length == 0 &&
      viewPort.type != PLATFORM.MOBILE
    ) {
      // console.log('Delete the widget');
      await editorSDK.application.uninstall('', { openConfirmation: false });
    }
  });
};
